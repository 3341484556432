import MasterLayout from "../layouts/master"
import React from "react";
import {
    Box, Button, Flex, SimpleGrid, chakra, Text, HStack, Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Container,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const FellowShip = () => {
    return <MasterLayout>
        <SimpleGrid
            columns={{
                md: 1,
                lg: 2,
                sm: 1,
            }}
            spacing={0}
            alignItems={"center"}
            _after={{
                bg: "brand.500",
                opacity: 0.25,
                pos: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: -1,
                content: '" "',
            }}
        >
            <Flex
                direction="column"
                alignItems="start"
                justifyContent="center"
                px={{
                    base: 4,
                    lg: 20,
                }}
                py={24}
            >
                <chakra.h1
                    mb={2}
                    fontSize={{
                        base: "4xl",
                        md: "6xl",
                        lg: "5xl",
                        sm: "4xl"
                    }}
                    fontWeight="bold"
                    color="brand.600"
                    _dark={{
                        color: "gray.300",
                    }}
                    lineHeight="shorter"
                >
                    <Text
                        display={{
                            base: "block",
                            lg: "inline",
                        }}
                        w="full"
                        bgClip="text"
                        bgGradient="linear(to-r, green.400,purple.500)"
                        fontWeight="extrabold"
                    >
                        Revolution in Education
                    </Text>{" "}starts from here.
                </chakra.h1>
                <chakra.p
                    pr={{
                        base: 0,
                        lg: 16,
                    }}
                    mb={8}
                    fontSize="sm"
                    color="brand.600"
                    _dark={{
                        color: "gray.400",
                    }}
                    letterSpacing="wider"
                >
                    Become the part of the revolution with us, get trained and bring revolution to the remotest areas of India
                </chakra.p>
                <HStack>
                        <Button
                            as="a"
                            href="https://forms.gle/7rnZygaHLryytQn78"
                            w={{
                                base: "full",
                                sm: "auto",
                            }}
                            variant="solid"
                            size="lg"
                            bg={'#52bb6a'}
                            color={'white'}
                            mb={{
                                base: 2,
                                sm: 0,
                            }}
                            cursor="pointer"
                        >
                            Apply Now
                        </Button>
                </HStack>
            </Flex>
            <StaticImage
                alt={"students"}
                w={"md"}
                objectFit={"cover"}
                height={"100%"}
                align={'center'}
                src={"../images/fellowship_bg.png"}
            />
        </SimpleGrid>
        <Container py={90} maxW={"6xl"}>
            <Text
                fontWeight={"bold"}
                fontSize={'1.2rem'}
                py={8}
            >
                Frequently Asked Questions
            </Text>
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Why should I join SunoKitaab Fellowship?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        SunoKitaab fellowship will introduce you to the real problem in our Indian education, most of our population is not able to get quality education which will allow them to capture better opertunities. This fellowship will build your skills towards real life problem and will build your leadership skills. You will honor yourself to become the part of the revolution and bring our country to the next level.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                Who are we looking for?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        We're just asking for your commitment towards the goal, as the headline suggests we're here to bring change. This is a 1 Year fellowship in which you'll be visiting schools in remote areas and demonstrate VidyaBox (audio learning device) which requires a good communication skill as well.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                How much will I be paid during the Fellowship?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        You will be paid Rs. 22,000 per month. In addition, Fellows will receive an allowance for shipping VidyaBox if needed.
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Container>
    </MasterLayout>
}

export default FellowShip